.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/*Zoho styles*/
#titlediv {
  visibility: hidden;
}

.showZohoTitleDiv #titlediv {
  visibility: visible;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Show and Hidden page */
.dp-hidden-page {
  opacity: 0;
  visibility: hidden;
}
