.iti {
  position: relative;
  display: inline-block;
  width: 100%;
}

.intl-tel-input {
  position: relative;
  display: inline-block;
}

.iti .iti__country-list {
  font-family: proxima-nova, Helvetica;
  font-size: 14px;
  width: auto;
  z-index: 100;
}

.captcha-legal-message p {
  font-size: 12px;
  line-height: 16px;
  font-family: proxima-nova, Helvetica;
}

.captcha-legal-message a {
  color: inherit;
}

.dp-flag-disabled .iti--allow-dropdown .iti__flag-container:hover {
  cursor: default;
}

.dp-flag-disabled .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
